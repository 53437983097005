import { db } from './assets/js/db.js';
import { page } from './assets/js/page.js'
import { toast } from './assets/js/toast.js';

import './assets/js/service.worker.js';
import 'animate.css/animate.min.css';

//import { jsPDF } from "jspdf";

window.onload = (event) => {
    // Initial Token Check
    db().getUser()
        .then((currentUser) => {
            if (currentUser) {
                document.querySelector('body').classList.remove('app-loading');
                document.querySelector('#app-login').classList.add('d-none');

                // Nav
                var currentPage = 'Precios Goodyear';
                let navLinks = document.querySelectorAll('.app-nav-link');
                navLinks.forEach(function (element) {
                    element.addEventListener('click', function (e) {
                        e.preventDefault();
                        let navActive = document.querySelector('.app-nav-active');

                        if (navActive) navActive.classList.remove('app-nav-active');
                        this.classList.add('app-nav-active');

                        currentPage = this.getAttribute('href');
                        if (currentPage === 'Clients') {
                            page().loadClients(currentUser.clients || []);
                        } else {
                            page().load(currentPage, currentUser);
                        }

                    }, true);
                });

                // Menu
                document.querySelector('#app-user-name').innerHTML = currentUser.name;
                document.querySelector('#menu-refresh').addEventListener('click', function () {
                    page().load(currentPage, currentUser, true);
                    db().refreshUser(true);
                }, true);
                document.querySelector('#menu-logout').addEventListener('click', function () {
                    toast().show('<b>Estás seguro que querés salir?</b><br>Vas a tener que solicitar un nuevo PIN de ingreso.<br><br>Hace click acá para confirmar o esperá unos segundos para cancelar.', 'warning', false, function () {
                        db().logOut();
                    })
                }, true);

                if (currentUser.role === 'VENDEDOR') {
                    db().fetchForm('Registro Visitas');
                    db().fetchForm('Registro Precios');
                    document.querySelector('.form-visitas').classList.remove('d-none');
                    document.querySelector('.form-precios').classList.remove('d-none');
                    document.querySelector('.nav-clientes').classList.remove('d-none');
                }

                // Initial Page Load
                page().load(currentPage, currentUser);

                // Refresh User
                if (window.navigator.onLine) db().refreshUser();

                // Forms
                let openFormsListener = document.querySelectorAll('.app-open-form');
                if (openFormsListener.length > 0) {
                    openFormsListener.forEach(function (element) {
                        element.addEventListener('click', function (e) {
                            e.preventDefault();
                            e.stopPropagation();

                            const name = this.getAttribute('data-form');
                            page().openForm(name);
                        }, true);
                    });
                }

                // Share All
                /*
                let btnShareAll = document.querySelector('.btn-share-all');
                btnShareAll.addEventListener('click', function () {
                    let productsToShare = document.querySelectorAll('[name="app-item-checkbox[]"]:checked');
                    console.log(productsToShare);
                    productsToShare.forEach((e) => {
                        page().getShareObject(currentPage, e.value)
                            .then((data) => {
                                console.log(data);
                            });
                    });

                });
                */
                /*

                function generateAndSharePDF() {
                    const doc = new jsPDF();

                    doc.text("Hola, este es un PDF generado con jsPDF!", 10, 10);

                    // Generar el PDF como un blob
                    const pdfBlob = doc.output('blob');

                    // Crear un archivo a partir del blob
                    const file = new File([pdfBlob], "documento.pdf", { type: "application/pdf" });

                    // Verificar si el navegador soporta la API de Web Share
                    if (navigator.canShare && navigator.canShare({ files: [file] })) {
                        navigator.share({
                            files: [file],
                            title: 'Compartir PDF',
                            text: 'Aquí tienes el PDF que generé',
                        })
                            .then(() => console.log('Compartido con éxito'))
                            .catch((error) => console.error('Error al compartir:', error));
                    } else {
                        console.error('La API de Web Share no es compatible con este navegador.');
                    }
                }
                    */

            } else {
                document.querySelector('body').classList.remove('app-loading');
                document.querySelector('#app-pin-wrap').classList.remove('d-none');
            }
        });

    // Login
    let pinPadDirty = false;
    let pinPad = document.querySelector('#app-pin-pad');
    pinPad.focus();

    pinPad.addEventListener('keyup', function (e) {
        if (e.key === 'Backspace') {
            pinPadDirty = false;
            return true;
        } else if (isNaN(Number(e.key)) || e.key === null || e.key === ' ') {
            this.value = '';
        } else if (this.value.length === 4) {
            if (!pinPadDirty) {
                pinPadDirty = true;
                document.body.classList.add('app-block');
                toast().show('Ingresando...', 'warning');
            }

            let pinPadTypeSelected = document.querySelector('[name="app-pin-type"]:checked').value || false;
            db().createToken(this.value, pinPadTypeSelected)
                .then((tokenCreated) => {
                    if (tokenCreated === true) {
                        location.reload();
                    } else if (tokenCreated.type === 'error') {
                        toast().show(tokenCreated.message, 'warning');
                        pinPadDirty = false;
                    }
                    document.body.classList.remove('app-block');
                })
        }
    }, true);

    let keyupEvent = new Event('keyup');
    document.querySelectorAll('[name="app-pin-type"]').forEach(function (el) {
        el.addEventListener('change', function () {
            console.log(pinPadDirty);
            pinPadDirty = false;
            pinPad.dispatchEvent(keyupEvent);
        });
    });
};