var settings = (function () {
    'use strict';
    return {
        version: 'GHM-28102024',
        googleRestUrl: function (tableName) {
            return (
                'https://script.google.com/macros/s/AKfycbxa2oBfZtU0EJc7Lo2W5o6sCbKCgOXeYYtoEdfJL_-l1eF93z_oN_U6Fvn35TqodP12Bg/exec' +
                '?path=' + tableName
            );
        },
        token: '',
        userType: 'user'
    };
})();

export { settings };